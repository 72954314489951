import React, { useState } from 'react';
import { Grid, CardActionArea, CardActions, CardMedia, Typography, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

const FacultyAchievementCard = ({ achievement, onCardClick, isLarge }) => {
  return (
    <>
      <CardActionArea className="container" onClick={() => onCardClick(achievement)}>
        <CardMedia
          component="img"
          height={isLarge ? "300" : "200"}
          image={achievement.image === "" ? "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg" : achievement.image}
          alt="No Image"
          onError={(e) => (e.target.src = "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg")}
          className="card_image_A"
          sx={{ objectFit: "cover"}}  
        />
      </CardActionArea>
      <CardActionArea>
        <CardContent className="m-4 py-2 px-0">
          <Typography gutterBottom variant="p" className="text-primary" style={{ fontSize: "12px" }}>
            {achievement.achived_date}
          </Typography>
          <Typography className="m-0" gutterBottom variant="h6" component="div">
            {achievement.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" className="multine-ellipsis">
            {achievement.description}
          </Typography>
        </CardContent>
        <CardActions className="d-flex px-0">
          {achievement.achieved_In.length > 0 &&
            achievement.achieved_In.map((item) => (
              <p key={item} className="px-2 py-1 achivemets_data">
                {item}
              </p>
            ))}
        </CardActions>
      </CardActionArea>
    </>
  );
};

const FacultyAchievements = ({ facultyAchievements }) => {
    const [selectedAchievement, setSelectedAchievement] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
  
    const handleCardClick = (achievement) => {
      setSelectedAchievement(achievement);
      setDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setSelectedAchievement(null);
      setDialogOpen(false);
    };
    
    return (
        <div className="container">
          <div className="my-4">
          <Typography variant="h4" className="text-center" component="h4">Faculty Achievements</Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item md={6} className="px-2 my-2">
              {facultyAchievements.length > 0 && (
                <FacultyAchievementCard achievement={facultyAchievements[0]} onCardClick={handleCardClick} isLarge={true} />
              )}
            </Grid>
            <Grid item md={6} container spacing={5}>
              {facultyAchievements.slice(1, 3).map((achievement, index) => (
                <Grid key={index} item className="d-flex">
                  <FacultyAchievementCard achievement={achievement} onCardClick={handleCardClick} isLarge={false} />
                </Grid>
              ))}
            </Grid>
          </Grid>
    
          <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Faculty Achievement Details</DialogTitle>
            <DialogContent>
              <Typography variant="h6">{selectedAchievement?.title}</Typography>
              <Typography component="img" 
            src={selectedAchievement?.image === "" ? "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg" : selectedAchievement?.image}
          alt="No Image"
          onError={(e) => (e.target.src = "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg")}
          className="container">
         </Typography>
              <Typography>{selectedAchievement?.description}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    };
    
    export default FacultyAchievements;