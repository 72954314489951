import React from "react";
import { Typography, Box } from "@mui/material";

const MVComponent = ({ getdata }) => {
  const data = getdata;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100%"
      className="text-center"
    >
      <Box>
        <Typography variant="h5" mb={2}>
          {data.title}
        </Typography>
        <Typography variant="subtitle2" className="container multiline-ellipsis-2 text-center body1_color">
          {data.description}
        </Typography>
      </Box>
      <Box mt={4}>
        <img
          src={
            data.image === ""
              ? "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
              : data.image
          }
          alt="MV-Data"
          style={{ width: '100%', height: '330px', objectFit: 'contain' }}
        />
      </Box>
    </Box>
  );
};

export default MVComponent;
