import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
// import InfoIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import WebsiteJson from '../../Assets/JSON/MV.json';
import { Typography } from '@mui/material';

const Infrastructure = () => {
    const infrastructure_data = WebsiteJson.Infra_Structure;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState("");

    const handleOpenDialog = (item) => {
        setSelectedItem(item);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (

        <>
        <section className="container py-10">
            <Typography variant='h4' className='text-center py-3'>Infrastructure of our College</Typography>
            <ImageList sx={{ width: "100%", height: 700 }} cols={3}>
                {infrastructure_data && infrastructure_data.map((item, index) => (
                    <ImageListItem className="my-2 mx-1" key={index} onClick={() => handleOpenDialog(item)}>
                        <img
                            src={item.ImageURL}
                            alt={item.Title}
                            loading="lazy"
                            style={{ width: '100%', height: "auto" }}
                            onError={(e) => { e.target.src = 'https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg'; }}
                        />
                        <ImageListItemBar
                            title={item.Title}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${item.Title}`}
                                >
                                    {/* <InfoIcon /> */}
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{selectedItem ? selectedItem.Title : ''}</DialogTitle>
                <DialogContent>
                <img
                            src={selectedItem.ImageURL}
                            alt={selectedItem.Title}
                            loading="lazy"
                            style={{ width: '100%', height: 300, objectFit: 'cover' }}
                            onError={(e) => { e.target.src = 'https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg'; }}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
           </section>
        </>
    );
};

export default Infrastructure;
